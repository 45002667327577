import * as React from "react";

function App() {
  return (
    <h2>
      Website is under development by{" "}
      <a href="https://www.yashwebdesign.com" target="_blank" rel="noopener noreferrer">
        yashwebdesign.com
      </a>
    </h2>
  );
}

export default App;
